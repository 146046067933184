// splash page

@import "page-include";

.splash-page,
.splash-page__inner {
    &::before {
        content: "";
        display: block;
        background-image: url("../../img/basic-fit-white-logo.png");
        background-repeat: no-repeat;
        background-size: 100%;
        width: 305px;
        height: 96px;
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.splash-page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(309.08deg, $white-smoke 64.55%, $white 101%);
    overflow: hidden;

    &::before {
        @include mq($until-dt-max) {
            width: 300px;
            height: 80px;
        }

        @include mq($laptop-st) {
            right: 65px;
            bottom: 65px;
        }

        @include mq($not-mobile-tablet) {
            left: auto;
            top: auto;
            right: 50px;
            bottom: 80px;
            transform: translate(0);
        }

        @include mq($tablet-max) {
            display: none;
        }
    }
}

.splash-page__inner {
    width: 96%;
    position: relative;

    @include mq($not-mobile-tablet) {
        width: 100%;
        max-width: 825px;
    }

    &::before {
        @include mq($not-mobile-tablet) {
            display: none;
        }

        @include mq($not-mobile) {
            top: -100px;
            width: 300px;
        }

        @include mq($tablet-max) {
            display: block;
            top: -60px;
            width: 200px;
            height: 100px;
        }
    }
}

.splash-page__header {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $white;
}

.splash-page__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 32px 24px;
    background-color: $white;
}

.splash-page__heading {
    padding: 20px 32px 0;
    font-size: 40px;
    line-height: 40px;
    text-align: left;

    @include mq($not-mobile-tablet) {
        font-size: 60px;
        line-height: 70px;
    }

    span {
        display: block;
    }
}

.splash-page__title {
    color: transparent;
    -webkit-text-stroke: 1px $black;
}

.splash-page__dropdown {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 12px;

    .flag-icon {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 12px;
        transform: translate(0, -50%);
    }
}

.splash-page__select {
    padding: 2px 0 2px 40px;
    background-color: $white;
    font-size: 18px;
    line-height: 22px;
}

.splash-page__cta {
    align-self: flex-start;
    margin-top: 12px;
    width: 100%;
}

.splash-page__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 12px;
}

.splash-page__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include mq($not-mobile) {
        max-width: 600px;
        margin-right: auto;
        margin-left: auto;
    }
}

.splash-page__links__item:not(:last-child)::after {
    content: " |";
    color: $white;
}

.splash-page__link {
    display: inline-block;
    padding: 2px 4px;
    font-size: 16px;
    line-height: 22px;
    color: $white;
    transition: background-color 0.2s, color 0.2s;

    &:hover {
        color: $purple-0;
    }
}
